import React, { useState, useEffect } from 'react';
import { AutoComplete, Input, Layout, Space, Table, Tag, Button, Modal, Form, Input as AntdInput, Select, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // 引入 axios 来发送 HTTP 请求

// 模拟随机数据生成
const getRandomInt = (max, min = 0) => Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}${idx}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            Found {query} on{' '}
                            <a
                                href={`/ship/api/v1/account/search?q=${query}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {category}
                            </a>
                        </span>
                        <span>{getRandomInt(200, 100)} results</span>
                    </div>
                ),
            };
        });


// 表格列定义
const columns = (toggleShowPassword, handleEdit, handleDelete) => [
    {
        title: '标签',
        key: 'tags',
        dataIndex: 'tags',
        render: (_, { tags }) => (
            <>
                {tags.map((tag) => {
                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                        color = 'volcano';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: '账号',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: '密码',
        dataIndex: 'password',
        key: 'password',
        render: (_, { password, showPassword, key }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* 默认显示为****** */}
                <span style={{ width: 150 }}>
                    {showPassword ? password : '******'}
                </span>
                {/* 眼睛图标切换显示密码 */}
                <span
                    onClick={() => toggleShowPassword(key)} // 通过 key 调用 toggleShowPassword
                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </span>
            </div>
        ),
    },
    {
        title: '操作',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <a onClick={() => handleEdit(record)}>编辑</a>
                <a onClick={() => handleDelete(record.key)}>删除</a>
            </Space>
        ),
    },
];

// 模拟的数据
const data = [
    // {
    //     key: '1',
    //     name: 'John Brown',
    //     age: 32,
    //     address: 'New York No. 1 Lake Park',
    //     tags: ['nice', 'developer'],
    //     password: 'password123', // 默认密码
    //     showPassword: false, // 默认隐藏密码
    // },
];


const Account = () => {
    const [options, setOptions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [filteredData, setFilteredData] = useState(data);
    const [searchData, setSearchData] = useState(data);
    const [tags, setTags] = useState([]); // 标签数据
    const [selectedTag, setSelectedTag] = useState(null);
    const [loading, setLoading] = useState(true); // 添加加载状态
    const [editingRecord, setEditingRecord] = useState(null); // 保存编辑的记录
    const [inputTag, setInputTag] = useState(''); // 用来输入标签
    const [inputTags, setInputTags] = useState([]); // 存储已输入的标签
    const navigate = useNavigate();

    // 请求数据
    const fetchData = async () => {
        setLoading(true); // 启动加载状态
        try {
            const response = await axios.get('/ship/api/v1/account/query'); // 假设从这个 API 获取数据
            console.log('请求数据成功:', response);
            setFilteredData(response.data); // 假设接口返回的数据是数组形式
            setSearchData(response.data)
        } catch (error) {
            console.error('请求数据失败:', error);
            message.info('请登录后使用');
            navigate('/login');
            // setFilteredData(data);
            // 这里可以设置错误状态来提示用户
        } finally {
            setLoading(false); // 请求结束，关闭加载状态
        }
    };

     // 请求标签数据
     const fetchTags = async () => {
        try {
            const response = await axios.get('/ship/api/v1/tags/query'); // 假设这个接口返回标签数据
            setTags(response.data); // 假设接口返回的是一个标签数组
        } catch (error) {
            console.error('获取标签数据失败:', error);
            // message.error('标签加载失败');
        }
    };

    // 页面加载时请求数据
    useEffect(() => {
        fetchData(); // 组件加载时请求数据
        fetchTags();
    }, []); // 空数组表示只在组件首次加载时执行

    // 搜索框功能：根据输入内容过滤数据
    const handleSearch = (value) => {
        // 清除空格并转为小写，避免大小写问题
        const searchValue = value.toLowerCase().trim();
        if (value) {
            // 过滤数据
            console.log('Search Value:', searchValue);
            const filtered = searchData.filter((item) => {
                // 判断 name 是否包含搜索值，tags 中任意一个标签是否包含搜索值
                const nameMatch = item.name.toLowerCase().includes(searchValue);
                const tagsMatch = item.tags.some(tag => tag.toLowerCase().includes(searchValue));

                return nameMatch || tagsMatch;
            });
            setFilteredData(filtered);
        } else {
            fetchData();
        }

    };

    const onSelect = (value) => {
        console.log('onSelect', value);
    };

    // 弹出新增账号的对话框
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.validateFields().then((values) => {
            console.log('Form Values:', values);
            console.log('Input Tags:', inputTags);
            values.tags = inputTags;
            // 如果有 editingRecord，说明是编辑操作，否则是新增操作
            if (editingRecord) {
                axios.post('/ship/api/v1/account/update', values)
                    .then(response => {
                        // 假设登录成功后返回的响应
                        if (response.status === 200) {
                            fetchData();
                            setIsModalVisible(false);
                        }
                    })
                    .catch(error => {
                        // 处理登录失败
                        const errorData = error.response.data;
                        console.log(errorData.message);
                        message.error(`修改失败，${errorData.message}`);
                    });
            } else {
                axios.post('/ship/api/v1/account/add', values)
                    .then(response => {
                        // 假设登录成功后返回的响应
                        if (response.status === 200) {
                            fetchData();
                            setIsModalVisible(false);
                        }
                    })
                    .catch(error => {
                        // 处理登录失败
                        const errorData = error.response.data;
                        console.log(errorData.message);
                        message.error(`添加失败，${errorData.message}`);
                    });
            }
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // 编辑操作
    const handleEdit = (record) => {
        setEditingRecord(record); // 设置编辑的记录
        form.setFieldsValue(record); // 填充表单字段
        setInputTags(record.tags || []); // 设置当前标签（在编辑时显示）
        setIsModalVisible(true); // 弹出对话框
    };

    // 删除操作
    const handleDelete = (key) => {
        Modal.confirm({
            title: '确认删除',
            content: '是否确认删除该账号？',
            onOk: () => {
                axios.delete(`/ship/api/v1/account/delete?key=${key}`)
                    .then(response => {
                        message.success('删除成功');
                        fetchData(); // 删除后刷新数据
                    })
                    .catch(error => {
                        message.error('删除失败');
                    });
            },
        });
    };

    // 标签过滤功能
    const handleTagChange = (value) => {
        setSelectedTag(value);
        if (value) {
            const filtered = searchData.filter((item) => item.tags.includes(value));
            setFilteredData(filtered);
        } else {
            setFilteredData(searchData); // 如果没有选择 tag，则显示所有数据
        }
    };

    // 处理输入标签
    const handleInputTagChange = (e) => {
        setInputTag(e.target.value);
    };

    const handleInputTagKeyDown = (e) => {
        if (e.key === 'Enter' && inputTag.trim() !== '') {
            setInputTags(prevTags => {
                if (!prevTags.includes(inputTag)) {
                    return [...prevTags, inputTag];
                }
                return prevTags;
            });
            setInputTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setInputTags(inputTags.filter(tag => tag !== tagToDelete));
    };


    // 用于切换每一行的密码显示状态
    const toggleShowPassword = (key) => {
        const updatedData = filteredData.map((item) =>
            item.key === key ? { ...item, showPassword: !item.showPassword } : item
        );
        setFilteredData(updatedData);
    };

    return (
        <Layout style={{ padding: '20px' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
                {/* 搜索框、Tag 下拉框和按钮 */}
                <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <AutoComplete
                        popupMatchSelectWidth={252}
                        style={{
                            width: 300,
                        }}
                        // options={options}
                        // onSelect={onSelect}
                        onSearch={handleSearch}
                        size="large"
                    >
                        <Input.Search size="large" placeholder="请输入关键字" enterButton />
                    </AutoComplete>

                    {/* 标签选择下拉框 */}
                    <Select
                        placeholder="选择标签"
                        style={{ width: 200 }}
                        onChange={handleTagChange}
                        value={selectedTag}
                    >
                        <Select.Option value={null}>全部标签</Select.Option>
                        {tags.map((tag) => (
                            <Select.Option key={tag} value={tag}>{tag}</Select.Option>
                        ))}
                    </Select>

                    {/* "新增"按钮 */}
                    <Button type="primary" onClick={showModal}>
                        新增
                    </Button>
                </Space>

                {/* 表格显示 */}
                <Table columns={columns(toggleShowPassword, handleEdit, handleDelete)}
                    dataSource={filteredData}
                    style={{ marginTop: 20 }} />
            </Space>

            {/* 账号添加/编辑对话框 */}
            <Modal
                title={editingRecord ? '编辑账号' : '添加账号'}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
            >
                <Form form={form} layout="vertical" name="addAccount">
                    <Form.Item
                        label="key称"
                        hidden="true"
                        name="key"
                    >
                        <AntdInput />
                    </Form.Item>
                    <Form.Item
                        label="账号名称"
                        name="name"
                        rules={[{ required: true, message: '请输入账号名称' }]}
                    >
                        <AntdInput />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: '请输入密码' }]}
                    >
                        <AntdInput.Password />
                    </Form.Item>
                    {/* <Form.Item
                        label="标签"
                        name="tags"
                        rules={[{ required: true, message: '请选择标签' }]}
                    >
                        <Select mode="multiple" placeholder="选择标签" allowClear>
                            {tags.map((tag) => (
                                <Select.Option key={tag} value={tag}>{tag}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                    <Form.Item label="标签" name="tags" rules={[{ required: true, message: '请输入标签' }]}>
                        <Input
                            value={inputTag}
                            onChange={handleInputTagChange}
                            onKeyDown={handleInputTagKeyDown}
                            placeholder="输入标签，按回车添加"
                        />
                        <div style={{ marginTop: 10 }}>
                            {inputTags.map((tag) => (
                                <Tag
                                    key={tag}
                                    closable
                                    onClose={() => handleDeleteTag(tag)}
                                >
                                    {tag}
                                </Tag>
                            ))}
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    );
};

export default Account;
