import { Button, Col, Form, Input, message, Row } from 'antd';
import axios from 'axios'; // 引入 axios，用于请求
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.less';

const RegistryForm = () => {
  const [form] = Form.useForm();  // 使用 Form.useForm() 获取 form 实例
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    // 在这里处理提交表单数据
    console.log('Received values:', values);
    axios.post('/ship/api/v1/user/registry', values)
      .then(response => {
        // 假设登录成功后返回的响应
        if (response.status === 200) {
          message.success('注册成功');
          // 跳转到首页或用户信息页面
          // window.location.href = '/home';  // 根据需要修改跳转路径
          navigate('/home'); 
        }
      })
      .catch(error => {
        // 处理登录失败
        const errorData = error.response.data;
        console.log(errorData.message);
        message.error(`注册失败，${errorData.message}`);
      });
  };

  return (
    <>
    <Row justify="center" style={{ marginTop: '8vh' }}>
      <Col span={8}>
        <Form 
          form={form} 
          onFinish={handleSubmit} 
          layout="vertical" // 使用 vertical 布局让标签和输入框排列在一列
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
              label="RePassword"
              name="repassword"
              dependencies={['password']} // 添加依赖，确保repassword变化时会校验
              rules={[
                { required: true, message: 'Please input your password again!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次输入密码不一致'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: false, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: false, message: 'Please input your phone!' }]}
          >
            <Input />
          </Form.Item>
          
          {/* 提交按钮 */}
          <Form.Item wrapperCol={{ span: 24 }} >
            <Button style={{ marginTop: '3vh' }} type="primary" htmlType="submit" block>
              注册
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>

    {/* 链接部分 */}
    <Row justify="center">
    <Col span={8}>
      <div style={{ textAlign: 'center' }}>
      <Link to="/login" className="default-link">
          已有账号？点击登录。
        </Link>
      </div>
    </Col>
  </Row>
  </>
  );
};

export default RegistryForm;
