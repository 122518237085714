import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgramToolsJSONFormat from './textTools/jsonFormat';
import ProgramToolsXMLFormat from './textTools/xmlFormat';

const { Content, Sider } = Layout;


const tooltems = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: '文本处理',
      children: [
        {
          key: '1-1',
          label: 'JSON格式化',
          // 使用 Link 进行页面跳转
        //   labelElement: <Link to="/programTools/text/json/format">JSON格式化</Link>
          labelElement: 'JSON格式化'
        },
        {
          key: '1-2',
          label: 'XML格式化',
          // 使用 Link 进行页面跳转
        //   labelElement: <Link to="/programTools/text/json/format">JSON格式化</Link>
          labelElement: 'XML格式化'
        }
      ],
    },
    {
      key: '2',
      icon: <LaptopOutlined />,
      label: '图形',
      children: [
        // {
        //   key: '2-1',
        //   label: '图形工具',
        //   labelElement: <Link to="/graph">图形工具</Link>
        // }
      ],
    },
    {
      key: '3',
      icon: <NotificationOutlined />,
      label: '视频',
      children: [
        // {
        //   key: '3-1',
        //   label: '视频播放',
        //   labelElement: <Link to="/video">视频播放</Link>
        // }
      ],
    }
  ];
const ProgramTools = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    // 当前内容的状态
  const [content, setContent] = useState(<ProgramToolsJSONFormat/>);
    // 路由变化时更新内容
  const navigate = useNavigate();
  
  const handleMenuClick = (e) => {
    // 根据菜单项设置内容
    if (e.key === '1-1') {
      setContent(<ProgramToolsJSONFormat />);
    //   navigate('/json');
    } else if (e.key === '1-2') {
      setContent(<ProgramToolsXMLFormat />);
    //   navigate('/graph');
    } else if (e.key === '3-1') {
    //   setContent(<VideoPage />);
    //   navigate('/video');
    }
  };
    return (
        <Layout
            style={{
                padding: '24px 0',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            <Sider
                style={{
                    background: colorBgContainer,
                }}
                width={200}
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    style={{
                        height: '100%',
                    }}
                    // items={tooltems}
                    items={tooltems.map(item => ({
                        ...item,
                        children: item.children.map(subItem => ({
                          ...subItem,
                          label: subItem.labelElement || subItem.label
                        }))
                      }))}
                    onClick={handleMenuClick}  // 监听菜单点击
                />
            </Sider>
            <Content
                style={{
                    padding: '0 24px',
                    height: '70vh',
                }}
            >
                 {content}
            </Content>
        </Layout>
    );
};
export default ProgramTools;