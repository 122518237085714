import React, { useState } from 'react';
import { Button, Input, Layout, message, theme } from 'antd';
import jsonlint from 'jsonlint-mod';
import JSON5 from 'json5';  // 导入 json5 库

const { Content } = Layout;

const JSONFormat = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [jsonData, setJsonData] = useState('');  // 存储输入的 JSON 数据
    const [error, setError] = useState('');        // 存储错误信息

    // 格式化按钮点击处理函数
    const handleFormat = () => {
        try {
            // 使用 jsonlint 解析 JSON 数据并获取错误
            const parsedData = jsonlint.parse(jsonData); // jsonlint 会抛出错误，带有行列信息
            // 格式化 JSON 数据为易读格式
            const formattedJson = JSON.stringify(parsedData, null, 4); // 4是缩进的空格数
            setJsonData(formattedJson);  // 将格式化后的 JSON 更新回输入框
            setError('');  // 清除错误信息
        } catch (err) {
            // 如果 JSON 解析失败，则提示错误并显示具体的行和列
            setError(`Invalid JSON at line ${err.line}, column ${err.column}: ${err.message}`);
            // message.error(`Invalid JSON at line ${err.line}, column ${err.column}: ${err.message}`);  // 弹出错误提示
        }
    };

    // 压缩按钮点击处理函数
    const handleCompress = () => {
        try {
            // 使用 json5 解析 JSON 数据
            const parsedData = JSON5.parse(jsonData);
            // 压缩 JSON 数据为单行
            const compressedJson = JSON.stringify(parsedData);
            setJsonData(compressedJson);  // 将压缩后的 JSON 更新回输入框
            setError('');  // 清除错误信息
        } catch (err) {
            // 如果 JSON 解析失败，则提示错误并显示具体的行和列
            setError(`Invalid JSON at line ${err.line}, column ${err.column}: ${err.message}`);
            // message.error(`Invalid JSON at line ${err.line}, column ${err.column}: ${err.message}`);  // 弹出错误提示
        }
    };

    return (
        <Layout
            style={{
                padding: '24px 0',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            <Content
                style={{
                    padding: '0 24px',
                    // minHeight: 280,
                    // height: '65vh',
                }}
            >
                {/* <h2>JSON 格式化</h2> */}
                {/* 输入框用于输入 JSON 数据 */}
                <Input.TextArea
                    name='json'
                    value={jsonData}  // 显示当前输入的 JSON 数据
                    onChange={(e) => setJsonData(e.target.value)}  // 输入内容更新
                    placeholder="Enter JSON"
                    autoSize={{ minRows: 15, maxRows: 20 }}
                />
                
                {/* 错误提示 */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {/* 格式化按钮 */}
                <Button 
                    type="primary" 
                    onClick={handleFormat}  // 点击格式化按钮时触发格式化函数
                    style={{ marginTop: 20, marginLeft: 10 }}
                >
                    格式化校验
                </Button>

                {/* 压缩按钮 */}
                <Button 
                    type="primary" 
                    onClick={handleCompress}  // 点击压缩按钮时触发压缩函数
                    style={{ marginTop: 20, marginLeft: 20 }}
                >
                    压缩
                </Button>
            </Content>
        </Layout>
    );
};
export default JSONFormat;