import React, { useState } from 'react';
import { Button, Input, Layout, message, theme } from 'antd';
import { XMLParser, XMLBuilder } from 'fast-xml-parser'; // 引入 fast-xml-parser

const { Content } = Layout;

const XMLFormat = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [xmlData, setXmlData] = useState('');  // 存储输入的 XML 数据
    const [error, setError] = useState('');      // 存储错误信息

    // 格式化按钮点击处理函数
    const handleFormat = () => {
        try {
            const parser = new XMLParser(); // 创建 XML 解析器
            const result = parser.parse(xmlData); // 解析 XML 数据
            const builder = new XMLBuilder({ format: true }); // 创建 XML 构建器
            const formattedXml = builder.build(result);  // 格式化 XML
            setXmlData(formattedXml);  // 将格式化后的 XML 更新回输入框
            setError('');  // 清除错误信息
        } catch (err) {
            setError(`Invalid XML: ${err.message}`);
            // message.error(`Invalid XML: ${err.message}`);
        }
    };

    // 压缩按钮点击处理函数
    const handleCompress = () => {
        try {
            const parser = new XMLParser(); // 创建 XML 解析器
            const result = parser.parse(xmlData); // 解析 XML 数据
            const builder = new XMLBuilder({ format: false }); // 创建压缩的 XML 构建器
            const compressedXml = builder.build(result);  // 压缩 XML
            setXmlData(compressedXml);  // 将压缩后的 XML 更新回输入框
            setError('');  // 清除错误信息
        } catch (err) {
            setError(`Invalid XML: ${err.message}`);
            // message.error(`Invalid XML: ${err.message}`);
        }
    };

    return (
        <Layout
            style={{
                padding: '24px 0',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
            }}
        >
            <Content
                style={{
                    padding: '0 24px',
                    // minHeight: 280,
                }}
            >
                <h2>XML 格式化</h2>

                {/* 输入框用于输入 XML 数据 */}
                <Input.TextArea
                    name='xml'
                    value={xmlData}  // 显示当前输入的 XML 数据
                    onChange={(e) => setXmlData(e.target.value)}  // 输入内容更新
                    placeholder="Enter XML"
                    autoSize={{ minRows: 15, maxRows: 20 }}
                />

                {/* 错误提示 */}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {/* 格式化按钮 */}
                <Button 
                    type="primary" 
                    onClick={handleFormat}  // 点击格式化按钮时触发格式化函数
                    style={{ marginTop: 20, marginRight: 10 }}
                >
                    格式化
                </Button>

                {/* 压缩按钮 */}
                <Button 
                    type="default" 
                    onClick={handleCompress}  // 点击压缩按钮时触发压缩函数
                    style={{ marginTop: 20 }}
                >
                    压缩
                </Button>
            </Content>
        </Layout>
    );
};

export default XMLFormat;
