import { Button, Col, Form, Input, message, Row } from 'antd';
import axios from 'axios'; // 引入 axios，用于请求
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// const { Link } = Typography;
import './index.less';

const LoginForm = () => {
  const [form] = Form.useForm();  // 使用 Form.useForm() 获取 form 实例
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    // 在这里处理提交表单数据
    console.log('Received values:', values);
    axios.post('/ship/api/v1/user/login', values)
      .then(response => {
        // 假设登录成功后返回的响应
        if (response.status === 200) {
          message.success('登录成功');
          console.log(response.data);
          const token = response.data.token; // 假设 token 存在于 response.data.token 中
          // 设置 token 到 cookie
          Cookies.set('token', token, { expires: 7 }); // 设置 cookie，有效期 7 天
          // 跳转到首页或用户信息页面
          // window.location.href = '/home';  // 根据需要修改跳转路径
          navigate('/home'); 
        }
      })
      .catch(error => {
        // 处理登录失败
        message.error('登录失败，请检查用户名和密码');
      });
  };

  return (
    <>
      <Row justify="center" style={{ marginTop: '10vh' }}>
        <Col span={8}>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"  // 使用 vertical 布局让标签和输入框排列在一列
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            {/* 提交按钮 */}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {/* 链接部分 */}
      <Row justify="center">
        <Col span={8}>
          <div style={{ textAlign: 'center' }}>
            <Link to="/registry" className="default-link">没有账号？请点击此处注册。</Link>
          </div>
        </Col>
      </Row>
    
    </>
  );
};

export default LoginForm;
