import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Modal, message, Space, Row, Col, Tag } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const { Header, Content, Footer } = Layout;

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // 设置 axios 拦截器来处理 401 错误
  // axios.interceptors.response.use(
  //   (response) => response, // 成功的请求
  //   (error) => {
  //     if (error.response) {
  //       // 当返回 401 错误时，跳转到登录页面
  //       message.error('认证失败，请重新登录');
  //       localStorage.removeItem('token'); // 清除认证信息
  //       navigate('/login'); // 跳转到登录页面
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  // 模拟获取当前登录用户信息（通常通过API请求）
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        // 请求当前登录用户信息
        const response = await axios.get('/ship/api/v1/user/get');
        // .catch(error => {
        //   if (error.response) {
        //     const redirectUrl = error.response.headers['location'];  // 获取 Location 头部
        //     console.log('Redirecting to:', redirectUrl);
        //     // 可以在这里处理重定向，比如跳转到登录页面
        //     // window.location.href = redirectUrl;
        //   } else {
        //     console.error('Request failed:', error);
        //   }
        // });
        console.log(response.data);
        setUserInfo(response.data);
      } catch (error) {
        message.error('请先登录');
        navigate('/login'); // 跳转到登录页面
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, []);

  // 处理用户信息提交
  const handleUpdateInfo = async (values) => {
    try {
      setLoading(true);
      // 假设通过接口更新用户信息
      console.log('Updating user info:', values);
      await axios.post('/ship/api/v1/user/update', values);
      message.success('用户信息更新成功');
      setUserInfo(values);
      setModalVisible(false); // 关闭弹窗
    } catch (error) {
      message.error('更新失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  // 处理密码更新
  const handleUpdatePassword = async (values) => {
    try {
      setLoading(true);
      // 假设通过接口更新用户密码
      console.log('Updating password:', values);
      await axios.post('/ship/api/v1/user/pwd/update', values);
      message.success('密码修改成功');
      setPasswordModalVisible(false); // 关闭弹窗
    } catch (error) {
      message.error('密码修改失败');
    } finally {
      setLoading(false);
    }
  };

  // 退出登录
  const handleLogout = () => {
    // 处理退出逻辑（例如清除本地存储，跳转到登录页等）
    // localStorage.removeItem('authToken');
    Cookies.remove('token');
    message.success('退出成功');
    // window.location.href = '/login'; // 重定向到登录页
    navigate('/login');
  };

  return (
    <Layout style={{ minHeight: '80vh' }}>
      <Content style={{ padding: '20px 50px' }}>
        <Row justify="center" style={{ marginTop: '8vh' }}>
          <Col span={8}>
            <div style={{ padding: 20, minHeight: 360 }}>
              <h3>基本信息</h3>
              <Form layout="Horizontal" initialValues={userInfo} form={form}>
                <Form.Item label="用户名" name="username">
                  {/* <Input prefix={<UserOutlined />} disabled /> */}
                  {/* <Tag color="blue">{userInfo.username}</Tag> */}
                  <Button type='text'>{userInfo.username}</Button>
                </Form.Item>
                <Form.Item label="邮箱" name="email">
                  <Button type='text'>{userInfo.email}</Button>
                  {/* <Tag color="blue">{userInfo.email}</Tag> */}
                </Form.Item>
                {/* <Input prefix={<MailOutlined />} value={userInfo.email}  disabled /> */}
                <Form.Item label="手机号" name="phone">
                {/* <Tag color="blue">{userInfo.phone}</Tag> */}
                  <Button type='text'>{userInfo.phone}</Button>
                </Form.Item>
                {/* <Input prefix={<PhoneOutlined />} value={userInfo.phone}  disabled /> */}
              </Form>
            </div>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '8vh' }}>
          <Col span={7}>
          <Space>
            <Button type="primary" onClick={() => setModalVisible(true)}>
              修改信息
            </Button>
            <Button type="default" onClick={() => setPasswordModalVisible(true)}>
              修改密码
            </Button>
            <Button type="danger" icon={<LogoutOutlined />} onClick={handleLogout}>
              退出登录
            </Button>
          </Space>
          </Col>
        </Row>
        {/* 修改信息弹窗 */}
        <Modal
          title="修改基本信息"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={userInfo}
            onFinish={handleUpdateInfo}
          >
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input prefix={<UserOutlined />} disabled />
            </Form.Item>
            <Form.Item
              label="邮箱"
              name="email"
              rules={[{ required: true, message: '请输入邮箱' }]}
            >
              <Input prefix={<MailOutlined />} />
            </Form.Item>
            <Form.Item
              label="手机号"
              name="phone"
              rules={[{ required: true, message: '请输入手机号' }]}
            >
              <Input prefix={<PhoneOutlined />} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                保存
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* 修改密码弹窗 */}
        <Modal
          title="修改密码"
          visible={passwordModalVisible}
          onCancel={() => setPasswordModalVisible(false)}
          footer={null}
        >
          <Form layout="vertical" onFinish={handleUpdatePassword}>
            <Form.Item
              label="当前密码"
              name="currentPassword"
              rules={[{ required: true, message: '请输入当前密码' }]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item
              label="新密码"
              name="newPassword"
              rules={[{ required: true, message: '请输入新密码' }]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item
              label="确认新密码"
              name="confirmPassword"
              dependencies={['newPassword']}
              rules={[
                { required: true, message: '请确认新密码' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('两次密码输入不一致');
                  },
                }),
              ]}
            >
              <Input.Password prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                修改密码
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
      {/* <Footer style={{ textAlign: 'center' }}>©2024 Created by Your Company</Footer> */}
    </Layout>
  );
};

export default UserInfo;
