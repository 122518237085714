import React from 'react';
import { Carousel } from 'antd';

const contentStyle = {
    margin: 0,
    height: '75vh',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const Home = () => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    return (
        <Carousel afterChange={onChange} autoplay={true} autoplaySpeed={3000} waitForAnimate={true}>
            <div>
                {/* <h3 style={contentStyle}>1</h3> */}
                <img src="/config/images/1.png" alt="Slide 1" style={{ width: '100vw', height: '65vh', objectFit: 'contain' }} />
            </div>
            <div>
                <img src="/config/images/2.png" alt="Slide 1" style={{ width: '100vw', height: '65vh', objectFit: 'contain' }} />
                {/* <h3 style={contentStyle}>2</h3> */}
            </div>
            <div>
                <img src="/config/images/3.png" alt="Slide 1" style={{ width: '100vw', height: '65vh', objectFit: 'contain' }} />
                {/* <h3 style={contentStyle}>3</h3> */}
            </div>
            <div>
                <img src="/config/images/4.png" alt="Slide 1" style={{ width: '100vw', height: '65vh', objectFit: 'contain' }} />
                {/* <h3 style={contentStyle}>4</h3> */}
            </div>
        </Carousel>
    );
};
export default Home;