import { ConfigProvider, Layout, Menu, theme } from 'antd';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import AccountPage from './pages/account';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import RegistryPage from './pages/registry';
import UserPage from './pages/user';
import ProgramTools from './pages/programTools';
import ProgramToolsJSONFormat from './pages/programTools/textTools/jsonFormat';

const { Header, Content, Footer } = Layout;

const App = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Layout>
        <Header style={{ display: 'flex', alignItems: 'center' }}>
          <div className="demo-logo" />
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" style={{ flex: 1, minWidth: 60 }}>
              <Link to="/">首页</Link>
            </Menu.Item>
            <Menu.Item key="2" style={{ flex: 1, minWidth: 100 }}>
              <Link to="/account">密码保险箱</Link>
            </Menu.Item>
            <Menu.Item key="3" style={{ flex: 1, minWidth: 100 }}>
              <Link to="/programTools">编程小工具</Link>
            </Menu.Item>
            <Menu.Item key="4" style={{ flex: 1, minWidth: 90 }}>
              <Link to="/user">我的信息</Link>
            </Menu.Item>
          </Menu>
        </Header>

        {/* Content */}
        <Content style={{ padding: '0 50px', marginTop: '15px', height: 'calc(75vh)', maxheight: 'calc(75vh)' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registry" element={<RegistryPage />} />
            <Route path="/programTools" element={<ProgramTools />} />
            <Route path="/programTools/text/json/format" element={<ProgramToolsJSONFormat />} />
          </Routes>
        </Content>

        {/* Footer */}
        <Footer style={{ textAlign: 'center'}}>
          My website ©{new Date().getFullYear()} Used for personal technical learning
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
